import {
  IEventCount,
  IEventWindow,
  IRealTimeStats,
  IResourceStats,
  IStats,
  Resource,
  TimeInterval,
} from "@today/api/tracker/types"
import stats from "../../../pages/dashboard/stats"

type MetricKey<T> = Exclude<keyof T, "_resource" | "_timeInterval">

const REAL_TIME_KEYS: MetricKey<IRealTimeStats>[] = [
  "atStationCount",
  "inCollectCount",
  "inDeliveryCount",
  "inTransportCount",
  "pendingCollectCount",
]

const EVENT_COUNT_KEYS: MetricKey<IEventCount>[] = [
  "deliveredCount",
  "deliveredInTimeCount",
  "holdCollectCount",
  "holdDeliveryCount",
  "lostCount",
  "pickUpCount",
  "quitCount",
]

const STATS_KEYS: MetricKey<IStats>[] = [...REAL_TIME_KEYS, ...EVENT_COUNT_KEYS]

export class RealTimeStats implements IRealTimeStats {
  atStationCount = 0
  inCollectCount = 0
  inDeliveryCount = 0
  inTransportCount = 0
  pendingCollectCount = 0

  constructor(stats?: IRealTimeStats) {
    REAL_TIME_KEYS.forEach((key) => {
      this[key] = stats?.[key] ?? 0
    })
  }

  merge(stats: IStats) {
    REAL_TIME_KEYS.forEach((key) => {
      this[key] += stats[key]
    })
  }
}

export class EventCount implements IEventCount {
  deliveredCount = 0
  deliveredInTimeCount = 0
  holdCollectCount = 0
  holdDeliveryCount = 0
  lostCount = 0
  pickUpCount = 0
  quitCount = 0

  readonly _resource: Resource
  readonly _timeInterval: TimeInterval

  constructor(
    eventCount?: IEventCount,
    resource: Resource = "all",
    timeInterval: TimeInterval = "target-deliver-time-realtime"
  ) {
    this._resource = resource
    this._timeInterval = timeInterval
    EVENT_COUNT_KEYS.forEach((key) => {
      this[key] = eventCount?.[key] ?? 0
    })
  }

  private _totalDeliverTargetCount?: number
  private _totalCollectTargetCount?: number

  get totalDeliverTargetCount() {
    return this.getTotalDeliverTargetCount()
  }

  protected getTotalDeliverTargetCount() {
    return (this._totalDeliverTargetCount ??= (() => {
      let targetKeys: MetricKey<IEventCount>[] = []
      switch (this._resource) {
        case "all":
        case "clients":
        case "region-sets":
        case "addresses":
          switch (this._timeInterval) {
            case "target-deliver-time-realtime":
              targetKeys = ["pickUpCount", "holdCollectCount"]
              break
            case "target-deliver-time-past":
              targetKeys = ["pickUpCount"]
              break
            case "event-time-realtime":
              targetKeys = [
                "deliveredCount",
                "holdDeliveryCount",
                "quitCount",
                "lostCount",
              ]
              break
            case "event-time-past":
              targetKeys = ["deliveredCount", "quitCount", "lostCount"]
              break
          }
          break
        case "drivers":
        case "organizations":
          switch (this._timeInterval) {
            case "target-deliver-time-realtime":
            case "event-time-realtime":
              targetKeys = [
                "deliveredCount",
                "holdDeliveryCount",
                "quitCount",
                "lostCount",
              ]
              break
            case "target-deliver-time-past":
            case "event-time-past":
              targetKeys = ["deliveredCount", "quitCount", "lostCount"]
              break
          }
          break
      }
      return targetKeys.reduce((acc, key) => acc + this[key], 0)
    })())
  }

  get totalCollectTargetCount() {
    return this.getTotalCollectTargetCount()
  }

  protected getTotalCollectTargetCount() {
    return (this._totalCollectTargetCount ??= (() => {
      let targetKeys: MetricKey<IEventCount>[]
      switch (this._timeInterval) {
        case "target-deliver-time-realtime":
        case "event-time-realtime":
          targetKeys = ["pickUpCount", "holdCollectCount"]
          break
        case "target-deliver-time-past":
        case "event-time-past":
          targetKeys = ["pickUpCount"]
          break
      }
      return (this._totalCollectTargetCount = targetKeys.reduce(
        (acc, key) => acc + this[key],
        0
      ))
    })())
  }

  get totalCount() {
    switch (this._resource) {
      case "all":
      case "clients":
        return this.totalDeliverTargetCount
      default:
        return this.totalDeliverTargetCount + this.totalCollectTargetCount
    }
  }

  private _deliveredRatio?: number
  private _deliveredInTimeRatio?: number
  private _holdRatio?: number
  private _quitRatio?: number
  private _lostRatio?: number

  get deliveredRatio() {
    return (this._deliveredRatio ??= Math.min(
      1,
      (this._deliveredRatio =
        this.deliveredCount / Math.max(1, this.totalDeliverTargetCount))
    ))
  }

  get deliveredInTimeRatio() {
    return (this._deliveredInTimeRatio ??= Math.min(
      1,
      this.deliveredInTimeCount / Math.max(1, this.totalDeliverTargetCount)
    ))
  }

  get holdRatio() {
    return (this._holdRatio ??= Math.min(
      1,
      (this.holdCollectCount + this.holdDeliveryCount) /
        Math.max(1, this.totalDeliverTargetCount)
    ))
  }

  get quitRatio() {
    return (this._quitRatio ??= Math.min(
      1,
      this.quitCount / Math.max(1, this.totalDeliverTargetCount)
    ))
  }

  get lostRatio() {
    return (this._lostRatio ??= Math.min(
      1,
      this.lostCount / Math.max(1, this.totalDeliverTargetCount)
    ))
  }

  private _remainingDeliveryCount?: number

  get remainingDeliveryCount() {
    return (this._remainingDeliveryCount ??= Math.max(
      0,
      this.totalDeliverTargetCount -
        (() => {
          switch (this._timeInterval) {
            case "target-deliver-time-realtime":
            case "event-time-realtime":
              return (
                this.deliveredCount +
                this.holdDeliveryCount +
                this.quitCount +
                this.lostCount
              )
            default:
              return this.deliveredCount + this.quitCount + this.lostCount
          }
        })()
    ))
  }

  static merge(eventCounts: EventCount[]) {
    const eventCount = new EventCount(
      undefined,
      eventCounts?.[0]?._resource,
      eventCounts?.[0]?._timeInterval
    )
    EVENT_COUNT_KEYS.forEach((key) => {
      eventCount[key] = eventCounts.reduce((acc, ec) => acc + ec[key], 0)
    })
    return eventCount
  }

  static avg(eventCounts: EventCount[]) {
    if (eventCounts.length === 0) return new EventCount()
    const eventCount = this.merge(eventCounts)
    const size = eventCounts.filter(
      (eventCount) => !eventCount.isEmpty()
    ).length
    EVENT_COUNT_KEYS.forEach((key) => {
      eventCount[key] /= Math.max(1, size)
    })
    return eventCount
  }

  static min(eventCounts: EventCount[], targetField: keyof EventCount) {
    const eventCount = new EventCount(
      undefined,
      eventCounts?.[0]?._resource,
      eventCounts?.[0]?._timeInterval
    )
    if (!eventCounts.length) return eventCount
    return (
      eventCounts.reduce((acc, ec) =>
        ec[targetField] < acc[targetField] ? ec : acc
      ) ?? eventCount
    )
  }

  static max(eventCounts: EventCount[], targetField: keyof EventCount) {
    const eventCount = new EventCount(
      undefined,
      eventCounts?.[0]?._resource,
      eventCounts?.[0]?._timeInterval
    )
    return eventCounts.reduce(
      (acc, ec) => (ec[targetField] > acc[targetField] ? ec : acc),
      eventCount
    )
  }

  isEmpty() {
    return EVENT_COUNT_KEYS.every((key) => this[key] === 0)
  }
}

export class Stats extends EventCount implements IStats {
  atStationCount = 0
  inCollectCount = 0
  inDeliveryCount = 0
  inTransportCount = 0
  pendingCollectCount = 0

  constructor(
    stats?: IStats,
    resource: Resource = "all",
    timeInterval: TimeInterval = "target-deliver-time-realtime"
  ) {
    super(stats, resource, timeInterval)
    REAL_TIME_KEYS.forEach((key) => {
      this[key] = stats?.[key] ?? 0
    })
  }

  merge(stats: Stats) {
    STATS_KEYS.forEach((key) => {
      this[key] += stats[key]
    })
  }

  private _statsTotalDeliverTargetCount?: number
  private _statsTotalCollectTargetCount?: number

  get totalDeliverTargetCount() {
    return (this._statsTotalDeliverTargetCount ??=
      super.getTotalDeliverTargetCount() +
      (() => {
        switch (this._resource) {
          case "drivers":
          case "organizations":
            switch (this._timeInterval) {
              case "target-deliver-time-realtime":
              case "event-time-realtime":
                return this.inDeliveryCount
            }
            break
        }
        return 0
      })())
  }

  get totalCollectTargetCount() {
    return (this._statsTotalCollectTargetCount ??=
      super.getTotalCollectTargetCount() +
      (() => {
        switch (this._resource) {
          case "drivers":
          case "organizations":
          case "region-sets":
          case "addresses":
            switch (this._timeInterval) {
              case "target-deliver-time-realtime":
              case "event-time-realtime":
                return this.pendingCollectCount
            }
            break
        }
        return 0
      })())
  }

  isEmpty() {
    return STATS_KEYS.every((key) => this[key] === 0)
  }

  clone(): Stats {
    return new Stats({ ...this }, this._resource, this._timeInterval)
  }
}

export class EventWindow extends EventCount implements IEventWindow {
  windowStartTime: string = ""
  windowEndTime: string = ""

  constructor(
    eventWindow?: IEventWindow,
    resource: Resource = "all",
    timeInterval: TimeInterval = "target-deliver-time-realtime"
  ) {
    super(eventWindow, resource, timeInterval)
    if (eventWindow) {
      this.windowStartTime = eventWindow.windowStartTime
      this.windowEndTime = eventWindow.windowEndTime
    }
  }

  static empty(eventWindow: EventWindow) {
    const emptyWindow = new this(
      undefined,
      eventWindow._resource,
      eventWindow._timeInterval
    )
    emptyWindow.windowStartTime = eventWindow.windowStartTime
    emptyWindow.windowEndTime = eventWindow.windowEndTime
    return emptyWindow
  }

  add(eventWindow: EventWindow) {
    EVENT_COUNT_KEYS.forEach((key) => {
      this[key] += eventWindow[key]
    })
  }
}

export class ResourceStats<K extends string> extends Stats {
  id: string // deprecated
  ids: { [k in K]: string }

  constructor(
    idKey: K | K[],
    stats: IResourceStats<K>,
    resource: Resource = "all",
    timeInterval: TimeInterval = "target-deliver-time-realtime"
  ) {
    super(stats, resource, timeInterval)
    if (typeof idKey === "string") {
      this.id = stats[idKey]
      this.ids = { [idKey]: stats[idKey] as string } as { [k in K]: string }
    } else {
      this.id = ""
      this.ids = Object.fromEntries(
        idKey.map((key) => [key, stats[key] as string])
      ) as { [k in K]: string }
    }
  }
}

export class DriverStats extends ResourceStats<"driverId"> {
  latestDeliverTime?: string
  latestPickUpTime?: string

  constructor(
    stats: IResourceStats<"driverId"> & {
      latestDeliverTime?: string
      latestPickUpTime?: string
    },
    resource: Resource = "drivers",
    timeInterval: TimeInterval = "target-deliver-time-realtime"
  ) {
    super("driverId", stats, resource, timeInterval)
    this.latestDeliverTime = stats.latestDeliverTime
    this.latestPickUpTime = stats.latestPickUpTime
  }

  override merge(stats: DriverStats) {
    super.merge(stats)
    if (
      this.latestDeliverTime &&
      stats.latestDeliverTime &&
      this.latestDeliverTime < stats.latestDeliverTime
    ) {
      this.latestDeliverTime = stats.latestDeliverTime
    } else if (!this.latestDeliverTime) {
      this.latestDeliverTime = stats.latestDeliverTime
    }
    if (
      this.latestPickUpTime &&
      stats.latestPickUpTime &&
      this.latestPickUpTime < stats.latestPickUpTime
    ) {
      this.latestPickUpTime = stats.latestPickUpTime
    } else if (!this.latestPickUpTime) {
      this.latestPickUpTime = stats.latestPickUpTime
    }
  }
}
