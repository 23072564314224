import { Location } from "@today/api/taker"
import { RegionSet } from "@today/api/tracker"
import { Feature, MultiPolygon, Point } from "@turf/turf"

export * from "./Stats"

export type RegionSetWithPolygon = Omit<RegionSet, "polygon"> & {
  polygon: Feature<MultiPolygon>
  centerOfMass: Feature<Point>
}

export type SiGunGuRegion = {
  id: string
  siDo: string
  siGunGu: string
  polygon: Feature<MultiPolygon>
  centerOfMass: Feature<Point>
}

function getDistanceFromLatLngInKm(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) {
  const radius = 6371
  const dLat = deg2rad(lat2 - lat1)
  const dLon = deg2rad(lon2 - lon1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  return radius * c
}

function deg2rad(deg: number) {
  return deg * (Math.PI / 180)
}

export function moveMap(map: naver.maps.Map, boundary: Boundary) {
  const coord = {
    north: boundary.maxLatitude,
    east: boundary.maxLongitude,
    south: boundary.minLatitude,
    west: boundary.minLongitude,
  }
  const dist = getDistanceFromLatLngInKm(
    boundary.maxLatitude,
    boundary.maxLongitude,
    boundary.minLatitude,
    boundary.minLongitude
  )
  if (dist < 1) {
    if (map.getZoom() < 16) {
      setTimeout(() => {
        map.setZoom(16, true)
      }, 500)
    }
    map.panTo(
      {
        lat: (boundary.maxLatitude + boundary.minLatitude) / 2,
        lng: (boundary.maxLongitude + boundary.minLongitude) / 2,
      },
      {
        duration: 300,
        easing: "easeOutCubic",
      }
    )
  } else {
    map.panToBounds(
      coord,
      {
        duration: 500,
        easing: "easeOutCubic",
      },
      {
        top: 80,
        right: 80,
        bottom: 80,
        left: 80,
      }
    )
  }
}

export class Boundary {
  minLatitude = 90
  maxLatitude = -90
  minLongitude = 180
  maxLongitude = -180

  constructor(locs?: Location[]) {
    locs?.forEach((l) => {
      this.feed(l)
    })
  }

  feed({ longitude, latitude }: Location) {
    if (this.minLatitude > latitude) {
      this.minLatitude = latitude
    }
    if (this.maxLatitude < latitude) {
      this.maxLatitude = latitude
    }
    if (this.minLongitude > longitude) {
      this.minLongitude = longitude
    }
    if (this.maxLongitude < longitude) {
      this.maxLongitude = longitude
    }
  }

  static union(boundaries: Boundary[]): Boundary {
    const res = new Boundary()
    for (const b of boundaries) {
      if (res.minLatitude > b.minLatitude) {
        res.minLatitude = b.minLatitude
      }
      if (res.maxLatitude < b.maxLatitude) {
        res.maxLatitude = b.maxLatitude
      }
      if (res.minLongitude > b.minLongitude) {
        res.minLongitude = b.minLongitude
      }
      if (res.maxLongitude < b.maxLongitude) {
        res.maxLongitude = b.maxLongitude
      }
    }
    return res
  }

  isIncludedIn(boundary: Boundary) {
    return (
      boundary.minLatitude <= this.maxLatitude &&
      this.minLatitude <= boundary.maxLatitude &&
      boundary.minLongitude <= this.maxLongitude &&
      this.minLongitude <= boundary.maxLongitude
    )
  }

  hasIntersectionWith(boundary: Boundary) {
    return !(
      this.maxLatitude <= boundary.minLatitude ||
      boundary.maxLatitude <= this.minLatitude ||
      this.maxLongitude <= boundary.minLongitude ||
      boundary.maxLongitude <= this.minLongitude
    )
  }
}
